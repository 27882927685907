@tailwind base;
@tailwind components;
@tailwind utilities;

.tableScrollbar::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

.tableScrollbar::-webkit-scrollbar-thumb {
  background-color: #fde583; /* Set the color of the thumb (the draggable handle) */
  border-radius: 6px; /* Set the border radius of the thumb */
}

.tableScrollbar::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Set the color of the track (the area behind the thumb) */
  border-radius: 6px; /* Set the border radius of the thumb */
}

/* .apexcharts-tooltip */
.apexcharts-tooltip .apexcharts-xaxistooltip {
  border: 0 !important;
  /* box-shadow: Add your theme.customShadows.z24 value here !important; */
  color: #c32929 !important;
  border-radius: 5px !important;
  background-color: #c1c1c1 !important;
}

/* .apexcharts-tooltip-title */
.apexcharts-tooltip-title {
  border: 0 !important;
  font-weight: 500;
  background-color: #fff !important;
  color: #000;
}

.Toastify__toast-body {
  font-size: 0.8rem;
  margin: 0 !important;
}

/* .apexcharts-xaxistooltip-bottom */
.apexcharts-xaxistooltip-bottom:before {
  border-bottom-color: transparent !important;
}

.apexcharts-xaxistooltip-bottom:after {
  border-bottom-color: #000 !important;
}

/* .apexcharts-legend */
.apexcharts-legend {
  padding: 0 !important;
}

/* .apexcharts-legend-series */
.apexcharts-legend-series {
  align-items: center;
  display: flex !important;
}

/* .apexcharts-legend-marker */
.apexcharts-legend-marker {
  margin-top: -2px !important;
  margin-right: 8px !important;
}

/* .apexcharts-legend-text */
.apexcharts-legend-text {
  line-height: 18px;
  text-transform: capitalize;
}
